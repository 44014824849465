/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

type Meta =
  | {
      name: string;
      content: unknown;
      property?: undefined;
    }
  | {
      property: string;
      content: unknown;
      name?: undefined;
    };

const SEO = ({
  description,
  lang = 'en',
  meta = [],
  title,
}: {
  description?: string;
  lang?: string;
  meta?: Meta[];
  title?: string;
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author {
              name
            }
            keywords
            description
            siteUrl
            social {
              fbAppId
              twitter
            }
            thumbnailUrl
          }
        }
      }
    `,
  );

  const { siteMetadata } = site;
  const finalDescription = description || siteMetadata.description;
  const finalTitle =
    title && title.length > 0 ? `${siteMetadata.title} | ${title}` : siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={siteMetadata.title}
      title={finalTitle}
      meta={[
        {
          name: `title`,
          content: finalTitle,
        },
        {
          name: `author`,
          content: siteMetadata.author.name,
        },
        {
          name: `keywords`,
          content: siteMetadata.keywords,
        },
        {
          name: `description`,
          content: finalDescription,
        },
        // Twitter Cards API
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: siteMetadata.social.twitter,
        },
        {
          name: `twitter:title`,
          content: finalTitle,
        },
        {
          name: `twitter:description`,
          content: finalDescription,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.social.twitter,
        },
        {
          name: `twitter:domain`,
          content: 'kristw.yellowpigz.com',
        },
        {
          name: 'twitter:image:src',
          content: siteMetadata.thumbnailUrl,
        },
        // Facebook Open Graph API
        {
          property: `fb:app_id`,
          content: siteMetadata.social.fbAppId,
        },
        {
          property: `fb:admins`,
          content: '629784645',
        },
        {
          property: `og:url`,
          content: siteMetadata.siteUrl,
        },
        {
          property: `og:title`,
          content: finalTitle,
        },
        {
          property: `og:site_name`,
          content: siteMetadata.title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `article:author`,
          content: siteMetadata.author.name,
        },
        {
          property: `og:description`,
          content: finalDescription,
        },
        {
          property: 'og:image:type',
          content: 'image/png',
        },
        {
          property: 'og:image',
          content: siteMetadata.thumbnailUrl,
        },
      ].concat(meta)}
    />
  );
};

export default SEO;
