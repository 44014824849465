import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { FacebookProvider, Like } from 'react-facebook';
import getLanguage from '../utils/getLanguage';

const Layout = ({ page = '', children }) => {
  const language = getLanguage();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            social {
              fbAppId
            }
          }
        }
      }
    `,
  );

  return (
    <FacebookProvider appId={site.siteMetadata.social.fbAppId}>
      <div className="languageBlock">
        <small>
          Language:&nbsp;
          {language === 'th' ? 'Thai' : <Link to={`/${page}?th`}>Thai</Link>}
          &nbsp;|&nbsp;
          {language === 'en' ? 'English' : <Link to={`/${page}?en`}>English</Link>}
        </small>
      </div>
      <div id="menuLayer">
        <div className="container">
          <div className="offset10 span2 menuBlock">
            <ul className="menu">
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
              <li>&nbsp;</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="offset1 span9 plateContainer">
            <div className="plate" id="plate_first">
              <div className="logoWrapper">
                <img alt="" src="/img/logo.png" />
              </div>
              <div className="clearBoth">&nbsp;</div>
              {children}
            </div>
            <div className="footer">
              <small>
                Copyright &copy; 2012-{new Date().getFullYear()} Benjawan Chanthaworakit &amp; Krist
                Wongsuphasawat
                <br />
                <span className="bestView">
                  Tested on Chrome, Firefox and Safari on Mac OS X. Good luck if you use IE.
                </span>
              </small>
            </div>
          </div>
          <div className="span2 menuWrapper">
            <div className="menuBlock">
              <ul className="menu">
                <li className={page === '' ? 'active' : ''} id="home">
                  <Link to={`/?${language}`}>Home</Link>
                </li>
                <li className={page === 'about' ? 'active' : ''} id="about">
                  <Link activeClassName="active" to={`/about?${language}`}>
                    About
                  </Link>
                </li>
                <li className={page === 'invitation' ? 'active' : ''} id="invitation">
                  <Link activeClassName="active" to={`/invitation?${language}`}>
                    Invitation
                  </Link>
                </li>
                <li className={page === 'location' ? 'active' : ''} id="location">
                  <Link activeClassName="active" to={`/location?${language}`}>
                    Location
                  </Link>
                </li>
                <li className={page === 'photos' ? 'active' : ''} id="photos">
                  <Link activeClassName="active" to={`/photos?${language}`}>
                    Photos
                  </Link>
                </li>
                <li className={page === 'vis' ? 'active' : ''} id="extras">
                  <Link activeClassName="active" to={`/vis?${language}`}>
                    Extras*
                  </Link>
                </li>
                <li className={page === 'guestbook' ? 'active' : ''} id="guestbook">
                  <Link activeClassName="active" to={`/guestbook?${language}`}>
                    Guestbook
                  </Link>
                </li>
                <li className={page === 'live' ? 'active' : ''} id="live">
                  <Link activeClassName="active" to="/live">
                    {/* Live! */}&nbsp;
                  </Link>
                </li>
              </ul>
            </div>
            <div className="socialBlock">
              <Like
                href="http://benandmuu.yellowpigz.com"
                width="450"
                layout="button_count"
                action="like"
                size="small"
                showFaces={false}
                share={false}
                font="arial"
              />
              <div style={{ height: 5 }}>&nbsp;</div>
              <a
                href="https://twitter.com/kristw"
                className="twitter-follow-button"
                data-show-count="false"
              >
                Follow @kristw
              </a>
            </div>
          </div>
        </div>
      </div>
    </FacebookProvider>
  );
};

export default Layout;
